/*@import url('https://fonts.googleapis.com/css2?family=Cookie&display=swap');*/

@import url('https://fonts.googleapis.com/css2?family=Cookie&family=Ubuntu&display=swap');


.oncode{
  font-family: 'Cookie', sans-serif;
  font-size:xx-large;
}

/* .honey{
  visibility: hidden;
} */

.spam{
  display: none;
}

.App {
  text-align: center;
}

.centre{

  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;

}

/* a{
  color:aliceblue;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /*background-color: #282c34; */
/*background: #666666;*/
  height: 85vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

font-family: 'Cookie', cursive;
  font-size: calc(10px + 2vmin);
  /* color: white; */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body{

  background:#F5F5F5;
  width: 100%;
  min-height: 100%;
font-family: 'Cookie', cursive;
font-family: 'Ubuntu', sans-serif;
}

.outer{
  width: 100%;
  min-height: 85vh;
  /*background: whitesmoke;*/
background: #F5F5F5;
  display: flex;
  justify-content: center;
  flex-direction: column;
  line-height:3em;
  text-align: left;

}

.nav-custom{

  height: 15vh !important;
}

.inner {

 
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  /* border-radius: 15px; */
  transition: all .3s;
}

.btn-lg{

  width: 100%;
  border-radius: 0%;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}

.form-control{

  border-radius: 0 !important;
}

.form-select{

  border-radius: 0 !important;
}

.center{

  text-align: center !important;
}


/* Profile */
.list-group{
border-radius: 0% !important;

}

.test{

  margin: auto !important;

}


.navbar>.container, .navbar>.container-fluid, .navbar>.container-lg, .navbar>.container-md, .navbar>.container-sm, .navbar>.container-xl, .navbar>.container-xxl{
/* background:  #167bff !important; */
/*background:  #1a1a1a !important;*/
background:  #2b2b2b !important;
/*font-family: 'LL Brown",Helvetica,Arial,Lucida,sans-serif'!important;*/
font-family: 'Cookie', cursive;
font-family: 'Ubuntu', sans-serif;
    font-weight: 550;
font-size: 0.9rem;
text-shadow: white -0.1px -0.3px 0.1em;

}

.bg-dpblack{
/*background: #1a1a1a !important;*/
background:  #2b2b2b !important;
}

.disapear{
  display: none;
}

.dpLogo{
height: 15vmin;
}

.navShadow {
 
  box-shadow: 0px 5px 10px #999;
}

.MuiStepper-root {
  color: #00000 !important;
}

.Mui-active{
 color: #00000 !important;
  padding: "3px";
  borderRadius: "50%";
  border: "1px solid #1976d2";
  marginY: "-4px";
}

.bg-dpGrey{
background:  #d3d3d3 !important;
}